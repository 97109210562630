import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import axios from 'axios';

//backdrop
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/styles";


const styles = theme => ({
   backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});


class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
     open: false
    }
  }

   handlSubmit = e =>{
        e.preventDefault();
        const data ={
            email: this.userName,
            password: this.password, 
        }
        
        this.setState({open: true})
        axios.post('api/login', data)
          .then(res => {
             this.setState({open: false})
             NotificationManager.success(<IntlMessages id="notification.successMessage"/>, <IntlMessages
              id="notification.titleHere" />);
         
            localStorage.setItem('token', res.data.token); 
            localStorage.setItem('UserData',JSON.stringify(res.data.user));
            this.props.history.push('app/chat');  
          }
        ).catch( err =>{
           this.setState({open: false})
            NotificationManager.error(<IntlMessages id="notification.errorMessage"/>, <IntlMessages
            id="notification.titleHere"/>);
            } 
        )
    };

 
  render() {
    const { classes } = this.props;
    return (
      <>
       <Backdrop className={classes.backdrop} open={this.state.open}>
        <CircularProgress color="inherit" />
       </Backdrop>
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            
              <img src={require("assets/images/logo.png")} alt="Travel" title="Travel" style={{width:'100px', height: '100px'}}/>
           
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Login To System</h1>
            </div>

            <div className="app-login-form">
              <form onSubmit={this.handlSubmit}>
                <fieldset>
                  <TextField
                    label="Email"
                    fullWidth
                    size="small"
                    onChange={e => this.userName = e.target.value}
                    margin="normal"
                    className="mt-1"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password"/>}
                    fullWidth
                    size="small"
                     onChange={e => this.password = e.target.value}
                    margin="normal"
                    className=""
                  />

                  <div className="mb-3 mt-5 d-flex align-items-center justify-content-between">
                     <Button variant="contained" color="primary" type="submit">
                      Login 
                   </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>

        </div>
       
      </div>
      <NotificationContainer/>
      </>
    );
  }
}

export default withStyles(styles)(SignIn);
