import teal from '@material-ui/core/colors/teal';

export default {

  
  palette: {
    primary: {
      light: '#ed1c24',
      main: '#ed1c24',
      dark: '#ed1c24',
      contrastText: '#fff'
    },
    secondary: {
      light: teal[300],
      main: teal['A700'],
      dark: teal[700],
      contrastText: '#fff'
    },

    white: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
};
